import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import discount from 'assets/icons/discount.svg';
import invalid from 'assets/icons/invalid.svg';
import list from 'assets/icons/list.svg';
import lock from 'assets/icons/lock.svg';
import searchFolder from 'assets/icons/search-folder.svg';
import stockExchange from 'assets/icons/stock-exchange.svg';
import unbalanced from 'assets/icons/unbalanced.svg';
import valid from 'assets/icons/valid.svg';

import aina from 'assets/images/aina-table.png';

const AdvantagesTable = () => {
  const [t] = useTranslation('home');

  const items = [stockExchange, discount, list, searchFolder, lock, unbalanced];

  return (
    <>
      <div className={`${styles.container} wow fadeInUp`}>
        <div className={styles.titleCol}>
          <p className={`${styles.title} wow fadeInUp`}>
            <span
              dangerouslySetInnerHTML={{
                __html: t('advantages.title'),
              }}
            ></span>
          </p>
        </div>
        <div className={styles.table}>
          <table>
            <thead>
              <tr className="wow fadeInUp">
                <th>&nbsp;</th>
                <th>{t('advantages.before')}</th>
                <th>
                  <img src={aina} width="261" height="64" />
                </th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <>
                  <tr>
                    <th>
                      <span
                        className="wow fadeInUp"
                        style={{ backgroundImage: `url(${item})` }}
                      >
                        {t(`advantages.header${index + 1}`)}
                      </span>
                    </th>
                    <td>
                      <span
                        className="wow fadeInUp"
                        style={{ backgroundImage: `url(${invalid})` }}
                      >
                        {t(`advantages.before${index + 1}`)}
                      </span>
                    </td>
                    <td>
                      <span
                        className="wow fadeInUp"
                        style={{ backgroundImage: `url(${valid})` }}
                      >
                        {t(`advantages.after${index + 1}`)}
                      </span>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AdvantagesTable;
